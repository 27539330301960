
import {
    defineComponent, defineAsyncComponent, computed
} from 'vue';
import { TabsData } from '@/views/distributor/third-party-device/types/common';
import router, { distributorBaseRouter, distributorSubRouter } from '@/router';
import { isCNServer } from '@/util/location';

export default defineComponent({
    props: {
        type: {
            type: String,
            default: 'project'
        },
        deviceType: {
            type: String,
            default: isCNServer() ? 'control' : 'camera'
        }
    },
    setup(props) {
        const activeName = computed(() => props.type);
        const tabsData: TabsData = {
            project: {
                label: WordList.ProperAllTextProject,
                name: 'project',
                component:
            defineAsyncComponent(() => import('@/views/distributor/third-party-device/components/project-device.vue'))
            },
            personal: {
                label: WordList.NavInHtmlMenuPersonal,
                name: 'personal',
                component:
            defineAsyncComponent(() => import('@/views/distributor/third-party-device/components/single-device.vue'))
            }
        };

        const changeActiveName = (active: string) => {
            router.push(`/${distributorBaseRouter}/${distributorSubRouter.thirdPartyDevice}?type=${active}`);
        };
        return {
            tabsData,
            activeName,
            changeActiveName
        };
    }
});
